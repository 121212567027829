// import { Router } from 'react-router-dom/dist/umd/react-router-dom.development';
import './App.css';
import AddData from './Components/AddData';
// import AddData from './Components/AddData';
import Navbar from './Components/Navbar';
import TableList from './Components/TableList';
import {BrowserRouter as Router, Route,  Routes } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      
        <Router>
          <Navbar />
          <Routes>
            <Route path='/' element={<TableList/>} />
            <Route path='/add' element={<AddData data={{mname:"", actor:'',actress:'', director:'', year:'', cameraman:'', producer:'', language:''}} method='post' />} />
          </Routes>
        </Router>

      

      


    </div>
  );
}

export default App;

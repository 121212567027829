import React, { useState } from 'react'
import { Box, Button, Grid,TextField } from '@mui/material'
import axios from 'axios'

const AddData = (props) => {

  var [inp,setInpu]= useState(props.data)
  console.log(props.data);

  const inputHandler = (e)=>{
    const {name, value}= e.target;
    setInpu((inp)=>({...inp,[name]:value}));
    console.log(inp);
  }

  const readHandler=()=>{

    if(props.method==='post'){
      axios.post('/api/movie',inp)
    .then((response)=>{

      
      alert('data added')
    })
    .catch(err=>console.log(err))
    }
    

    if(props.method==='put'){
      axios.put(`/api/movie/${inp._id}`, inp)
      .then((response)=>{
        alert('data added')
        window.location.reload(false)
      })
  }

  

  
  }

  return (
    <div>

<Grid container style={{padding:'90px', display: 'block'}}>
  <Grid item xs={12}>
    <Box p={1}>
    <TextField id="outlined-basic" name='mname' value={inp.mname}  onChange={inputHandler} label="Movie Name" variant="outlined" />
    </Box>
    <Box p={1} >
    <TextField id="outlined-basic" name='actor' value={inp.actor}  onChange={inputHandler} label="Actor" variant="outlined" />
    </Box>
    <Box p={1} >
    <TextField id="outlined-basic" name='actress' value={inp.actress}  onChange={inputHandler} label="Actress" variant="outlined" />
    </Box>             
    <Box p={1} >
    <TextField id="outlined-basic" name='director' value={inp.director}  onChange={inputHandler} label="Director" variant="outlined" />
    </Box>
    <Box p={1} >
    <TextField id="outlined-basic" name='year' value={inp.year}  onChange={inputHandler} label="Released Year" variant="outlined" />
    </Box>
    <Box p={1} >
    <TextField id="outlined-basic" name='cameraman' value={inp.cameraman}  onChange={inputHandler} label="cameraman" variant="outlined" />
    </Box>
    <Box p={1} >
    <TextField id="outlined-basic" name='producer' value={inp.producer}  onChange={inputHandler} label="Producer" variant="outlined" />
    </Box>
    <Box p={1} >
    <TextField id="outlined-basic" name='language' value={inp.language}  onChange={inputHandler} label="Language" variant="outlined" />
    </Box>
    <Box p={1} >
    <Button variant="outlined" onClick={readHandler}>Add to movie</Button>
    </Box>

  </Grid>

</Grid>
    </div>
  )
}

export default AddData
import { Button, AppBar, Box, CssBaseline, Toolbar, Typography, } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'


const Navbar = () => {
  return (
    <div>
        
        <Box sx={{ display: 'flex' }}>
            <CssBaseline/>
            <AppBar sx={{ mr: 0 }}>
                
                <Toolbar>
                    <Typography variant='h5'  >
                        Movie Management
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            
          </Typography>
          <Button color="inherit"><Link to={'/'}  style={{textDecoration:'none', color:'white'}}>Home</Link></Button>
          <Button color="inherit"><Link to={'/add'}  style={{textDecoration:'none', color:'white'}}>Add Movie</Link>   </Button>
          <Button color="inherit">About Us</Button>

                </Toolbar>
            </AppBar>

        </Box>

    </div>
  )
}

export default Navbar
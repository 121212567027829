import {Button, Grid, Table,TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Paper from '@mui/material/Paper';
import axios from 'axios';

import React, { useEffect, useState } from 'react'
import AddData from './AddData';

const TableList = () => {
    var [movies,setMovies]=useState([]);
    var [update,setUpdate]= useState(false)
    var [singleValue, setSingleVlue]=useState([])

    useEffect(()=>{
        axios.get("/api/movie")
        .then((res)=>{
            setMovies(res.data)
        })
    },[])

    const handleDelete = (id) =>{
        axios.delete(`/api/movie/${id}`)
        .then((res)=>{
            alert('Moview deleted ')
            setMovies(movies.filter((movie) => movie._id !== id));
        })
        .catch((error)=>{
            console.error(error);
        })
    }

    const updateValue = (value)=>{
        console.log('click');
        setUpdate(true)
        setSingleVlue(value)

    }
 
    var finalJSX = <Grid container p={4} style={{paddingTop:'90px'}}>

    <Grid item xs={12}>
    <TableContainer component={Paper} >
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
            <TableRow>
            <TableCell>Movie Name</TableCell>
            <TableCell align="right">Actor</TableCell>
            <TableCell align="right">Actress</TableCell>
            <TableCell align="right">Director</TableCell>
            <TableCell align="right">Released Year</TableCell>
            <TableCell align="right">Cameraman</TableCell>
            <TableCell align="right">Producer</TableCell>
            <TableCell align="right">Language</TableCell>
            <TableCell align="right">Updates</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {movies.map((val,i)=>{
                return(
                    <TableRow key={i}>
            <TableCell component="th" scope="row">{val.mname}</TableCell>
      <TableCell align="right">{val.actor}</TableCell>
      <TableCell align="right">{val.actress}</TableCell>
      <TableCell align="right">{val.director}</TableCell>
      <TableCell align="right">{val.year}</TableCell>
      <TableCell align="right">{val.cameraman}</TableCell>
      <TableCell align="right">{val.producer}</TableCell>
      <TableCell align="right">{val.language}</TableCell>
      <TableCell align="right">
        <Button onClick={()=>updateValue(val)}>Edit</Button>
       <Button onClick={() => handleDelete(val._id)}>Delete</Button></TableCell>
            </TableRow>
                )
            })}
            
        </TableBody>
    </Table>

</TableContainer>

    </Grid>

</Grid>

    if (update) finalJSX = <AddData data={singleValue} method ='put' />
  return finalJSX
}

export default TableList